"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DropZone_1 = require("./Components/DropZone");
var FileInput_1 = require("./Components/FileInput");
var PasteMarkupInput_1 = require("./Components/PasteMarkupInput");
var sw_1 = require("./sw");
require("./main.css");
new DropZone_1.default();
new FileInput_1.default();
new PasteMarkupInput_1.default();
sw_1.serviceWorker();
