"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var invisibleElems = [
    'defs',
    'g',
    'foreignObject',
    'svg',
    'style',
    'title',
    'desc',
];
var CroppedSVG = /** @class */ (function () {
    function CroppedSVG(svg, filename, width, height) {
        this.svg = svg;
        this.filename = filename;
        this.width = width;
        this.height = height;
        this.width = width;
        this.height = height;
        this.filename = filename;
        this.coords = {
            top: Infinity,
            left: Infinity,
            bottom: -Infinity,
            right: -Infinity,
        };
        this.svg = svg;
        this.render();
    }
    CroppedSVG.prototype.removeAttributes = function () {
        this.svg.removeAttribute('viewBox');
        this.svg.removeAttribute('width');
        this.svg.removeAttribute('height');
    };
    CroppedSVG.prototype.filterSVGToVisibleElements = function (svg) {
        function flatten(ops, n) {
            ops.push(n);
            if (n.childNodes && n.childNodes.length) {
                // @ts-ignore: Unreachable code error
                [].reduce.call(n.childNodes, flatten, ops);
            }
            return ops;
        }
        var result = [svg].reduce(flatten, []).filter(function (elem) {
            var parentElement = elem.parentElement;
            return (elem.tagName &&
                !invisibleElems.includes(elem.tagName) &&
                (elem.getBoundingClientRect().width ||
                    elem.getBoundingClientRect().height) &&
                !parentElement.hasAttribute('mask') &&
                parentElement.tagName !== 'defs' &&
                (getComputedStyle(elem).stroke !== 'none' ||
                    getComputedStyle(elem).fill !== 'none'));
        });
        return result;
    };
    CroppedSVG.prototype.getCoords = function () {
        var _this = this;
        this.filterSVGToVisibleElements(this.svg).forEach(function (x, index, arr) {
            var _a = x.getBoundingClientRect(), newTop = _a.top, newLeft = _a.left, newBottom = _a.bottom, newRight = _a.right;
            var stroke = getComputedStyle(x)['stroke'];
            var strokeWidth = Number(getComputedStyle(x).strokeWidth.replace('px', ''));
            if (stroke !== 'none') {
                newTop = newTop - strokeWidth / 2;
                newLeft = newLeft - strokeWidth / 2;
                newBottom = newBottom + strokeWidth / 2;
                newRight = newRight + strokeWidth / 2;
            }
            if (newTop < _this.coords.top) {
                _this.coords.top = newTop;
            }
            if (newLeft < _this.coords.left) {
                _this.coords.left = newLeft;
            }
            if (newRight > _this.coords.right) {
                _this.coords.right = newRight;
            }
            if (newBottom > _this.coords.bottom) {
                _this.coords.bottom = newBottom;
            }
        });
    };
    CroppedSVG.formatViewBoxNum = function (num) {
        return Number(num.toFixed(2)) * 1;
    };
    CroppedSVG.prototype.setNewAttributes = function () {
        this.svg.setAttribute('viewBox', CroppedSVG.formatViewBoxNum(this.coords.left) + " " + CroppedSVG.formatViewBoxNum(this.coords.top) + " " + CroppedSVG.formatViewBoxNum(this.coords.right - this.coords.left) + " " + CroppedSVG.formatViewBoxNum(this.coords.bottom - this.coords.top));
        this.svg.parentElement.classList.add('is-showingSvg');
    };
    CroppedSVG.prototype.addSvg = function () {
        function handleImageEnhance(e) {
            var target = e.target;
            target.classList.toggle('is-enhanced');
        }
        // Add SVG to the screen inside the enhance button
        var enhanceBtn = document.createElement('button');
        enhanceBtn.classList.add('EnhanceButton');
        enhanceBtn.addEventListener('click', handleImageEnhance);
        enhanceBtn.appendChild(this.svg);
        var previewSectionElem = document.querySelector('.PreviewSection');
        previewSectionElem.appendChild(enhanceBtn);
    };
    CroppedSVG.prototype.render = function () {
        this.addSvg();
        this.removeAttributes();
        this.getCoords();
        this.setNewAttributes();
    };
    return CroppedSVG;
}());
exports.default = CroppedSVG;
