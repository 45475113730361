"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ColorToggleButton = /** @class */ (function () {
    function ColorToggleButton(svg, filename) {
        this.svg = svg;
        this.filename = filename;
        this.addColorToggle();
    }
    ColorToggleButton.prototype.handleColorToggle = function (e) {
        var mainElement = document.querySelector('.MainContent');
        mainElement.classList.toggle('is-blackBg');
        var target = e.target;
        if (target.textContent === 'Preview on black') {
            target.children[0].textContent = 'Preview on white';
            target.setAttribute('title', "Preview on white");
        }
        else {
            target.children[0].textContent = 'Preview on black';
            target.setAttribute('title', "Preview on black");
        }
    };
    ColorToggleButton.prototype.addColorToggle = function () {
        // add a11y text
        var a11yText = document.createElement('span');
        a11yText.classList.add('AccessibleText');
        a11yText.textContent = "Preview on black";
        // add color toggle button
        var colorToggleWrap = document.createElement('div');
        colorToggleWrap.classList.add('ColorToggleWrap');
        colorToggleWrap.setAttribute('title', "Preview on black");
        var blackColorBtn = document.createElement('button');
        blackColorBtn.classList.add('ColorToggleButton');
        blackColorBtn.appendChild(a11yText);
        blackColorBtn.addEventListener('click', this.handleColorToggle);
        colorToggleWrap.appendChild(blackColorBtn);
        var previewSectionElem = document.querySelector('.PreviewSection');
        previewSectionElem.appendChild(colorToggleWrap);
    };
    return ColorToggleButton;
}());
exports.default = ColorToggleButton;
