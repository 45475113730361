"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var MultipleDownloadButton = /** @class */ (function () {
    function MultipleDownloadButton(svgs) {
        this.svgs = svgs;
        this.svgs = svgs;
        this.addMultipleDownloadButton();
    }
    MultipleDownloadButton.prototype.saveAs = function (blob, filename) {
        if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
            return navigator.msSaveOrOpenBlob(blob, filename);
        }
        else if (typeof navigator.msSaveBlob !== 'undefined') {
            return navigator.msSaveBlob(blob, filename);
        }
        else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            elem.style.display = 'none';
            elem.style.opacity = '0';
            elem.style.color = 'transparent';
            (document.body || document.documentElement).appendChild(elem);
            if (typeof elem.click === 'function') {
                elem.click();
            }
            else {
                elem.dispatchEvent(new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                }));
            }
            URL.revokeObjectURL(elem.href);
        }
    };
    MultipleDownloadButton.prototype.clickHandler = function (zip, buttonElem) {
        return __awaiter(this, void 0, void 0, function () {
            var prevText, content;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        prevText = '';
                        if (this.svgs.length > 50) {
                            prevText = buttonElem.innerText;
                            buttonElem.setAttribute('disabled', '');
                            buttonElem.innerText = 'Downloading...';
                        }
                        return [4 /*yield*/, zip.generateAsync({ type: 'blob' })];
                    case 1:
                        content = _a.sent();
                        this.saveAs(content, 'SVGCropFiles.zip');
                        if (this.svgs.length > 50) {
                            buttonElem.removeAttribute('disabled');
                            buttonElem.innerText = prevText;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MultipleDownloadButton.prototype.addMultipleDownloadButton = function () {
        return __awaiter(this, void 0, void 0, function () {
            var JSZip, zip, buttonElem, buttonWrapElem;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.svgs.length < 1) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.resolve().then(function () { return require('jszip'); })];
                    case 1:
                        JSZip = _a.sent();
                        zip = new JSZip();
                        this.svgs.forEach(function (eachSvg) {
                            var svg = eachSvg.svg, filename = eachSvg.filename;
                            // Serialize the svg to string
                            var svgString = new XMLSerializer().serializeToString(svg);
                            // Remove any characters outside the Latin1 range
                            var decoded = unescape(encodeURIComponent(svgString));
                            // Now we can use btoa to convert the svg to base64
                            var base64 = btoa(decoded);
                            zip.file(filename, base64, { base64: true });
                        });
                        buttonElem = document.createElement('button');
                        buttonElem.textContent = "Download " + this.svgs.length + " Files";
                        buttonElem.classList.add('DownloadButton');
                        buttonElem.addEventListener('click', this.clickHandler.bind(this, zip, buttonElem));
                        buttonWrapElem = document.querySelector('.ButtonWrap');
                        buttonWrapElem.appendChild(buttonElem);
                        return [2 /*return*/];
                }
            });
        });
    };
    return MultipleDownloadButton;
}());
exports.default = MultipleDownloadButton;
