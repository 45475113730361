"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utilities_1 = require("../utilities");
var CopyToClipboardButton = /** @class */ (function () {
    function CopyToClipboardButton(svg, filename) {
        this.svg = svg;
        this.filename = filename;
        this.svg = svg;
        this.filename = filename;
        this.addCopyToClipboardButton();
    }
    CopyToClipboardButton.prototype.addCopyToClipboardButton = function () {
        var buttonWrapElem = document.querySelector('.ButtonWrap');
        var buttonElem = document.createElement('button');
        buttonElem.textContent = 'Copy to clipboard';
        buttonElem.classList.add('CopyButton');
        buttonElem.addEventListener('click', utilities_1.copyToClipboard);
        buttonWrapElem.appendChild(buttonElem);
    };
    return CopyToClipboardButton;
}());
exports.default = CopyToClipboardButton;
