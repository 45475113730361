"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CopyInput = /** @class */ (function () {
    function CopyInput(svg, filename) {
        this.svg = svg;
        this.filename = filename;
        this.svg = svg;
        this.filename = filename;
        this.addCopyInput();
    }
    CopyInput.prototype.addCopyInput = function () {
        var copyInput = document.createElement('input');
        copyInput.classList.add('CopyInput');
        copyInput.setAttribute('aria-hidden', '');
        copyInput.value = this.svg.outerHTML;
        var mainElement = document.querySelector('.MainContent');
        mainElement.appendChild(copyInput);
    };
    return CopyInput;
}());
exports.default = CopyInput;
